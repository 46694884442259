.App {
  text-align: center;
  padding-bottom: 40px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.flex{

  display: flex;
}
ul li {
  list-style: none;
}
.todayList{
  text-align: "center";
  margin-bottom: -5px;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/*** AMuro ***/
#Login {
  padding-top:55px;
}
.span-error{
  display: block;
  color: red;
  text-align: center;
}
img{
  max-width: 100%;
  height: auto;
}
.MuiFormControl-root.textNote{
  width:100%;
  margin-bottom: 5px;
}
.alertText{
  display:inline-block;
  padding: 0 10px;
  color:blue;
  font-size: 14px;
  line-height: 30px;
  text-align: left !important;
}
.alertButton{
  display: inline-block;
}
#Login .MuiFormControl-root{
  margin-top: 15px;
  margin-bottom: 15px;
}
#btn-login {
  margin-top: 15px;
  margin-bottom: 15px;
}
.MuiChip-root.freeSession,
.MuiBadge-root.freeSession .MuiBadge-badge{
  background-color: #80e180;
}
.MuiChip-root.freeSession .MuiAvatar-colorDefault{
   background-color: #fff;
} 
label.MuiFormControlLabel-root span.Mui-checked + .MuiTypography-body1{
  color:#f50057;
  font-weight: 500;
}
#user-1.makeStyles-wrapper-228 {
  border-left: 2px solid red;
}
.header-section{
  text-align: center;
  padding: 8px 0;
  font-size: 1.3rem;
  text-transform: uppercase;
  font-weight: 400;
  color: #fff;
}
.header-section__funcional{
  background-color: rgb(173,31,92);
}
.header-section__pilates{
  background-color: #ece1d3;
}
.header-section__yoga{
  background-color: #d7dec1;
}
.header-section__readaptacion{
  background-color: #5746bb;
}
#goToday,#historial,#prev,#next{
  margin-top: 8px;
}
#sessionfuncional .MuiFormControl-marginNormal{
	margin-top: 8px;
	margin-bottom: 8px;
}
#sessionreadaptacion .MuiFormControl-marginNormal{
	margin-top: 8px;
	margin-bottom: 8px;
}
.group_active_0 .titleList{
  border-left: solid 3px red !important;
}
    .titleList {
    color: #fff;
    font-size: 1rem;
    text-align: left;
    padding: 6px 8px;
  display: flex;
  justify-content: space-between;
align-content: flex-end

    }

    .titleList span {
      font-size: 0.9em;
    }

    #adminfuncional .titleList {
    background-color: rgb(173,31,92);
    }

    #adminpilates .titleList {
    background-color:#ece1d3;
    }

    #adminyoga .titleList {
    background-color: #d7dec1;
    }

    #sessionfuncional .titleList {
    background-color: rgb(173,31,92);
    }

    #adminreadaptacion .titleList {
      background-color: #5746bb;
    }

    #sessionpilates .titleList {
    background-color: #ece1d3;
    }

    #sessionyoga .titleList {
    background-color: #d7dec1;
    }

    #sessionreadaptacion .titleList {
      background-color: #5746bb;
    }

    .userList {
      flexGrow: 1;
    }
    .fab {
      position: fixed !important;
      background-color: #25D366 !important;
      color: #fff !important;
      bottom: 15px;
      z-index: 4;
      
    }
    .fab--right{
    	right: 15px;
    }
    .fab--left{
    	left: 15px;
    }
    .fab--left.fab--edit{
      background-color: #000 !important;
      color: #fff;
      left: 64px;
      z-index: 3;
    }
    .fab--left.fab--add{
      background-color: #fff !important;
      z-index: 2;
      left: 15px;
    }
    .fab--add .MuiIcon-fontSizeLarge{
    	font-size:48px;
    }
    .responsive{
      max-width: 100%;
      height: auto;
    }

    #sessionfuncional .MuiInputBase-adornedEnd,
    #sessionpilates .MuiInputBase-adornedEnd{
      max-width: 45px;
    }

       #sessionfuncional .MuiInputBase-adornedEnd .MuiButtonBase-root,
       #sessionpilates .MuiInputBase-adornedEnd .MuiButtonBase-root {
        padding:15px 6px 5px 6px;
      }

    .nodisplay{
      display: none !important;
    }

    .MuiSwitch-colorSecondary.Mui-checked.Mui-disabled {
      color: #e897b3;
    }

    .MuiSwitch-colorSecondary.Mui-checked.Mui-disabled + .MuiSwitch-track {
        background-color: #e897b3;
    }

.noVisible{
  visibility: hidden;
}

.editGroup{
  float: right;
}

.MuiDrawer-root .MuiAvatar-circle{
  margin: 6px auto;
}

.MuiFormControl-fullWidth{
	margin-bottom: 20px !important;
}

#new-group .MuiFormGroup-root{
	float: left;
	width: 45%;
}

.MuiChip-root.active{
	background-color: #2c00ff;
  color: #ccc !important;
}
.MuiChip-root.active .MuiChip-avatarSmall{
  background-color: #fff;
color: #000;
font-weight: 600;
}
.MuiChip-root.subactive{
  background-color:#2c00ff ;
  color: #ccc !important;
}
.MuiChip-root.subactive .MuiChip-avatarSmall{
  background-color: #fff;
color: #000;
font-weight: 600;
}
.MuiChip-root.waitCheck .MuiChip-avatarSmall{
  background-color: #fff;
color: #000;
font-weight: 600;
}
.list-await .MuiAvatar-colorDefault {
  background-color: #9986f4;
  width: 32px;
  height: 32px;
}
.fulldialog_content{
  padding: 0 !important;
  min-height: 200px;
  text-align: center;
}
.top-reservas{
  padding: 6px 0;
}

#month-select{
  width: 85px;
}
.group_card .MuiCardContent-root{
  padding: 4px;
}
.group_card .MuiChip-sizeSmall{
  height: 20px;
}
.group_card .MuiTypography-caption{
  line-height: 1.26;
}
.carditem{
  width: 100%;
display: flex;
flex-wrap: wrap;
box-sizing: border-box;
}
.carditem .MuiPaper-root{
   margin: 3px 0 0 1px;
   width: 19%;
}
.carditem .MuiPaper-root.group_assigned {
  background-color: #deebfb80;
}
.carditem.hoy{
  border:1px solid red;
}
.MuiCard-root.type_F,
.MuiCard-root.type_P,
.MuiCard-root.type_O,
.MuiCard-root.type_M{
  min-height: 80px;
}
.MuiCard-root.type_F{
  background-color: rgb(173,31,92);
    color: #fff;
}
.MuiCard-root.type_P{
  background-color: #aaa8a8cc;

}
.MuiCard-root.type_M{
  background-color: #a9c2c3;
  color: #fff;
}
.MuiCard-root.type_F p.MuiTypography-root,
.MuiCard-root.type_P p.MuiTypography-root,
.MuiCard-root.type_O p.MuiTypography-root,
.MuiCard-root.type_M p.MuiTypography-root{
  font-size:0.8em;
}
.MuiCard-root.type_F .MuiCardContent-root,
.MuiCard-root.type_P .MuiCardContent-root,
.MuiCard-root.type_M p.MuiTypography-root{
  padding: 5px;
}

#date-picker-dialog{
  visibility: hidden;
  overflow: hidden;
  width: 1px;
}
.MuiGrid-grid-xs-12 .MuiInput-underline::before{
  border: none !important;
}

#fulldialog .MuiCardContent-root{
  padding: 5px !important;
  min-height: 70px;
}

.quincena_Q1{
  color:blue;
  font-weight: 700;
}
.quincena_Q2{
  color:#15e0c4;
  font-weight: 700;
}
.topadminlist{
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.buttonAction{
  display: flex;
  flex-wrap: wrap;
}
.topuser{
  padding: 10px 0px;
}
.optionuser{
    padding: 5px 0px;
}
.search{
  width: 260px;
}
.topdashboard{
  padding-top:10px;
}
#btn-pilates,#btn-funcional,
#btn-yoga,#btn-nutricion,
#btn-readapta{
  padding: 2px 5px;
}
.btnsave{
  margin-top: 20px;
  margin-right: 15px;
  font-weight: bold;
}
.userName{
  border: #ccc solid 1px;
  border-radius: 4px;
  width: 150px;
}
#single-dialog-title{
  padding: 10px;
}
#single-dialog-title h2{
  padding: 0;
}
.userName .MuiInputBase-input{
  padding:3px 5px;
}
.selectOrder,.selectFilter{
	width: 140px;
}
.selectOrderMat,.selectFilterMat,.selectUserStateMat{
  width: 105px;
}
.topactions{
	display: inline-flex;
  align-items: center;
  justify-content: center;
}

.topinformer .divider{
  height: 35px !important;
  margin: 5px !important;
}
.btnsearch{

}
.topinformer{
  width: 100%;
  display: inline-flex;
  padding: 5px 0;  
}
.topinformer #month-select,
.topinformer #type-select{
  width: 85px;
}
.topinformer #year-select{
  width: 54px;
  margin-right: 5px;
  margin-right: 5px;
}
.MuiAvatar-root.infgrupo{
  width: 32px;
  height: 32px;
  margin: 0 1px;
  font-size:14px;
}
.MuiAvatar-root.infavatar{
  width: 26px;
  height: 26px;
  margin: 0 1px;
  font-size:12px;
}
.MuiAvatar-root.inftotal{
  border:#000 solid 1px;
  background-color: #fff;
  color: #000;
}
.MuiAvatar-root.infasiste{
  background-color: #fff;
  border:#3f51b5 solid 1px;
  color: #000;
}
.MuiAvatar-root.inffaltas{
  background-color: #fff;
  border:red solid 1px;
  color: #000;
}
.MuiAvatar-root.inflibres{
  border:#80e180 solid 1px;
  background-color: #fff;
  color: #000;
}

#fulldialog .MuiAvatar-root.inftotal{
  border:#000 solid 1px;
  background-color: #000;
  color: #fff;  
}

#fulldialog .MuiAvatar-root.infasiste{
  border:#3f51b5 solid 1px;
  background-color: #fff;
  color: #000;  
}

#fulldialog .MuiAvatar-root.inffaltas{
  border:#d21212 solid 1px;
  background-color: #fff;
  color: #000;  
}

#fulldialog .MuiAvatar-root.inflibres{
  border:#80e180 solid 1px;
  background-color: #fff;
  color: #000;  
}
#leyenda{
  display: none;
  align-items: center;
  justify-content: center;
  padding: 5px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
#leyenda.leyendaon{
  display: inline-flex;
  width: 100%;
}

#leyenda .MuiTypography-caption{
  margin: 0 2px;
}

.MuiAvatar-root.avinfo{
  width: 32px;
  height: 32px;

  background-color: #fff;
} 

.MuiAvatar-root.avinfo.avlevel{
  color: #000;
  font-weight: 700;
  margin-right: 2px;
}

.MuiAvatar-root.avinfo.quincena_Q1{
  color:blue;
  font-weight: 700;
  border:blue solid 1px;
}
.MuiAvatar-root.avinfo.quincena_Q2{
  color:#15e0c4;
  font-weight: 700;
  border:#15e0c4 solid 1px;
}

.MuiChip-root.grouptype_F{
  background-color:rgb(173,31,92);
  color:#fff;
}
.MuiChip-root.grouptype_R{
  background-color:#3f51b5;
  color:#fff;
}
.pay_transfer{
  background-color:#ccf5eb;
}
.standard-adornment-payment{
  background-color: #ebebeb;
}
.wrapform{
  display: inline-flex;
  flex-wrap: wrap;
}
.wrapform .MuiTextField-root{
    margin: 5px 3px;
}
.wrapform .MuiInputBase-input{
    width: 145px;
}
.bottomform{
  margin: 12px 0;
  text-align: center;
}
.bottomform .MuiTextField-root{
  width: 92%;
}
.userbar{
  width: 100%;
}
.wrapgroups{
   display: inline-flex;
  flex-wrap: wrap; 
}
.wrapgroups .usergroup{
  width: 20%;
  padding: 0;
}
.wrapgroups .MuiCardContent-root{
  padding: 5px;
}
.wrapgroups .MuiCardContent-root:last-child{
  padding: 5px;
}
.MuiTypography-h6{
  padding: 8px 0;
}
.header-section_admin{
  background-color: #3f51b5;
}
.adminreservas{
  padding: 10px 4px;
}
.adminbooking .MuiCardContent-root{
  padding: 5px;
}

.matriculas .MuiListItem-container{
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
}
.item-text__left{
  width: 210px;
}
.userDialog .topuser{
  text-align: center;
}
#opyiondialog .MuiDialogContent-root{
  padding-left: 10px;
  padding-right: 10px;
}
#opyiondialogedit .MuiDialogContent-root,
#opyiondialoggroup .MuiDialogContent-root{
  padding:0;
}
.chip{
  margin: 3px 0;
  padding: 2px;
  display: block;
  width: 100%;
  border-radius: 6px;
  padding: 0 0 2px 0;
}
.chip span{
  font-size: 13px;
  padding: 3px 6px;
}
.F_select{
   background-color:rgb(173,31,92);
   color: #fff;
}
.F_unselect{
   background-color:rgba(173, 31, 92, 0.15);
}

.P_select{
   background-color: #a8a8a8;
   color: #fff;
}
.P_unselect{
   background-color:#e0e0e085;
}
.R_unselect{
  background-color:#3f51b53b;
}

.R_select{
  background-color: #3f51b5;
  color: #fff;
}
.B_select{
   background-color: #ff8888a3;
   color: #fff;
}
.B_unselect{
   background-color:#ffb3b34d;
}
.chip span.total{
  color:#111;
  font-weight: bold;
}
.chip span.free{
  color:#80e180;
  font-weight: bold;
}
.chip span.wait{
  color:blue;
  font-weight: bold;
}
.userEdit{
  padding-top: 10px;
}

.topuser div{
  text-align: center;
}
.topuser .MuiGrid-grid-xs-2 {
  flex-grow: 0;
  max-width: 18.666667%;
  flex-basis: 18.666667%;
}
.listNotes{
  margin-bottom: 6px;
}
.userpay_0{
  background-color:#ff9b9b;
}
.userpay_1{
  background-color: #97cc97;
}
.userpay_0.usermetodo_2{
  background-color:#ffe19b;
}
.userpay_0.userstate_0{
  background-color:#ff8c26;
}
.userpay_1.atrasado_1{
  background-color:#c0f087;
}
.titleReadaptacion{
  color:#00a0ac;
}
.linkReadaptacion a{
  color:#00a0ac;
  text-decoration: none;
}
.titleNutricion{
  
}
.infoReadaptacion{
  color:#00a0ac;
}
.infoNutricion{
  color:#46c14b;
}
.infoCuerpo{
  color:#a4044e;
}
.linkNutricion a{
  color:#46c14b;
  text-decoration: none;
}
.userActive_0{
 background-color:#ff8c26;
}
.userActive_1{
  border-right: 6px solid blue;
}
.userActive_B{
 background-color:#fd40bc;
}
.infavatar.contab{
  float: left;
}
.MuiTableCell-sizeSmall{
  padding: 4px 8px !important;
}
.stRed{
  font-size: 12px;
  color:red;
}
.stGreen{
  font-size: 12px;
  color:green;
}
.MuiButton-root.buttonCard{
  font-size: 10px !important;
  padding: 6px 0!important;
  line-height: 12px;
}
.userAlert_1{
  color: red;
}
.topuser .MuiButton-containedSizeSmall{
  min-width: 55px;
  max-width: 55px;
  padding: 4px;
}
#sessionText{
  border:#333 solid 1px;
  padding: 4px;
}